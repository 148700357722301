import { useStore } from "@nanostores/solid";
import { $currentLocation } from "../stores/currentLocation.ts";
import * as pluscodes from "pluscodes";
import { createEffect, createSignal } from "solid-js";

export default function CurrentLocationToggle() {
	// global
	const currentLocation = useStore($currentLocation);
	// local
	const [autoStoreSelection, setAutoStoreSelection] =
		createSignal<boolean>(true);
	// refs
	let geolocationWatchId: number;

	createEffect(() => {
		if (autoStoreSelection()) {
			geolocationWatchId =
				globalThis?.navigator?.geolocation.watchPosition(
					({ coords }) => {
						const plusCode = pluscodes.encode(
							{
								latitude: coords.latitude,
								longitude: coords.longitude,
							},
							8,
						);

						const { storeName, prettyStoreName } =
							getStoreFromPlusCode(plusCode ?? "");

						$currentLocation.set({
							plusCode: plusCode ?? "",
							latitude: String(coords.latitude),
							longitude: String(coords.longitude),
							storeName,
							prettyStoreName,
						});
					},
					function (error) {
						$currentLocation.set({
							plusCode: "",
							latitude: "",
							longitude: "",
							storeName: "",
							prettyStoreName: "N/A",
						});
					},
					{
						enableHighAccuracy: true,
						maximumAge: 60000, // 1 minute
					},
				);
		} else {
			globalThis?.navigator?.geolocation.clearWatch(geolocationWatchId);

			$currentLocation.set({
				plusCode: "",
				latitude: "",
				longitude: "",
				storeName: "",
				prettyStoreName: "",
			});
		}
	});

	return (
		<div class="columns">
			<div class="column stores-container has-text-centered is-flex is-flex-direction-column is-justify-content-center">
				<label class="label">{`Location: ${currentLocation().prettyStoreName || "N/A"}`}</label>
				<div class="field is-flex is-justify-content-center is-align-items-center">
					<span class="control">
						<button
							type="button"
							classList={{
								button: true,
								"is-info": !autoStoreSelection(),
							}}
							onClick={() => setAutoStoreSelection(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16px"
								viewBox="0 0 512 512"
							>
								<path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
							</svg>
						</button>
					</span>

					<span class="control">
						<button
							type="button"
							classList={{
								button: true,
								"is-info": autoStoreSelection(),
							}}
							onClick={() => setAutoStoreSelection(true)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16px"
								viewBox="0 0 384 512"
							>
								<path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
							</svg>
						</button>
					</span>
				</div>
			</div>
		</div>
	);
}

function getStoreFromPlusCode(plusCode = ""): {
	storeName: string;
	prettyStoreName: string;
} {
	switch (plusCode) {
		case "85PR4R23+":
		case "85PR4R24+":
			return {
				storeName: "sams_club",
				prettyStoreName: "Sam's Club",
			};
		case "85PR3RX6+":
			return {
				storeName: "target",
				prettyStoreName: "Target",
			};
		// northern Walmart
		case "85PR3RX2+":
		case "85PR3RW2+":
		// southern walmart
		case "85PR2QMJ+":
			return {
				storeName: "walmart",
				prettyStoreName: "Walmart",
			};
		case "85PR3QMP+":
			return {
				storeName: "breadroot",
				prettyStoreName: "Breadroot",
			};
		case "85PR3RR9+":
		case "85PR3RQ9+":
			return {
				storeName: "menards",
				prettyStoreName: "Menards",
			};
		case "85PR4Q6J+":
		case "85PR4Q5J+":
			return {
				storeName: "menards",
				prettyStoreName: "Lowes",
			};
		case "85PR3QXF+":
			return {
				storeName: plusCode,
				prettyStoreName: "Home",
			};
		default:
			return {
				storeName: plusCode,
				prettyStoreName: plusCode,
			};
	}
}